/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/Profile.js";
import JobPost from "views/JobPost.js";
import ManagerProfile from "views/ManagerProfile.js";
import ManagerProfileNew from "views/ManagerProfileNew.js";
import Maps from "views/Maps.js";
import Register from "views/Register.js";
import Login from "views/Login.js";
import Tables from "views/Tables.js";
import Managers from "views/Managers.js";
import Requisitions from "views/Requisitions.js";
import Engagements from "views/Engagements.js";
import Compatibility from "views/Compatibility.js";
import Search from "views/Search.js";
import SearchResults from "views/SearchResults.js";
import Icons from "views/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    hide: true
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
    hide: true
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
    hide: true
  },
  {
    path: "/hm-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: ManagerProfile,
    layout: "/admin",
    hide: true
  },
  {
    path: "/hm-profile-new",
    name: "Add New User",
    icon: "ni ni-single-02 text-yellow",
    component: ManagerProfileNew,
    layout: "/admin",
    hide: true
  },
  {
    path: "/job-details",
    name: "Requisition Details",
    icon: "ni ni-single-02 text-yellow",
    component: JobPost,
    layout: "/admin",
    hide: true
  },
  {
    path: "/engagements",
    name: "Engagements",
    icon: "ni ni-single-02 text-yellow",
    component: Engagements,
    layout: "/admin",
    hide: true
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
    hide: true
  },
  {
    path: "/managers/hm",
    name: "Team Leads",
    icon: "ni ni-briefcase-24 text-blue",
    component: Managers,
    layout: "/admin"
  },
  {
    path: "/managers",
    name: "Users",
    icon: "ni ni-circle-08 text-blue",
    component: Managers,
    layout: "/admin"
  },
  {
    path: "/requisitions",
    name: "Requisitions",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Requisitions,
    layout: "/admin"
  },
  {
    path: "/compatibility",
    name: "Personal Compatibility",
    icon: "ni ni-compass-04 text-blue",
    component: Compatibility,
    layout: "/admin"
  },
  {
    path: "/search",
    name: "Search",
    icon: "ni ni-atom text-red",
    component: Search,
    layout: "/admin"
  },
  {
    path: "/search-results",
    name: "Search Results",
    icon: "ni ni-atom text-red",
    component: SearchResults,
    layout: "/admin",
    hide: true
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    hide: true
  },
  {
    path: "/user-profile",
    name: "My Settings",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    hide: true
  },
  {
    path: "/compatibility",
    name: "Compatibility",
    icon: "ni ni-single-02 text-yellow",
    component: Compatibility,
    layout: "/admin",
    hide: true
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-grey",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    hide: true
  }
];
export default routes;
