/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import data from "../data.js";

const Requisitions = () => {

  const requisitionsData = data.requisitions;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">

                  <h3 className="mb-0">Job requisitions</h3>
                  <br/>
                  <Button
                    color="primary"
                    size="sm"
                  >
                    Add New
                  </Button>

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Team lead</th>
                      <th scope="col">Status</th>
                      <th scope="col">Tools</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requisitionsData.map(job => {
                      const path = `/admin/job-details/${job.uid}/`;
                      const engagementsPath = `/admin/engagements/${job.uid}/`;
                      const hms = job.hm.map(hm_uid => {
                        return data.users.find(manager => { return manager.uid === hm_uid })
                      });

                      return (
                        <tr>
                          <td>
                          <span className="mb-0 text-sm">
                            <a href={path} data-id="hm1">{job.title}</a>
                          </span>
                          </td>
                          <td>
                            {hms.map(hm => {
                              return (
                                <div><a href={`/admin/hm-profile/${hm.uid}/`}>{hm.firstName} {hm.lastName}</a></div>
                              );
                            })}
                          </td>
                          <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            Active
                          </Badge>
                          </td>
                          <td><a href={engagementsPath}>View Engagements</a></td>
                          </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
}

export default Requisitions;
