/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Label,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeaderStripped.js";
import { useHistory } from 'react-router-dom';

import talents from "../talents.js";

const ManagerProfileNew = () => {

  const skills = ["Leadership ","Leadership Skills ","Microsoft Office ","Office for Mac ","Customer Service ","Microsoft Excel ","Java ","Word ","Microsoft Word ","PowerPoint ","Microsoft PowerPoint ","Social Media ","Social Networks ","C++","Training ","Marketing ","Data-driven Marketing","Software Development ","Public Speaking ","C - 16813"];
  const langs  = ["Albanian","Amharic","Arabic","Belorussian","Bengali","Bulgarian","Chinese","Croatian","Czech","Danish","Dutch","English","Estonian","Finnish","Flemish","French","German","Greek","Hebrew","Hindi","Hungarian","Icelandic","Inuit","Irish","Italian","Japanese","Javanese","Korean","Lahnda","Latvian","Lithuanian","Luxemburgish","Macedonian","Mongolian","Maltese","Polish","Portuguese","Romanian","Russian","Serbian","Slovak","Slovene","Spanish","Swedish","Turkish","Ukranian","Urdu","Vietnamise","Yiddish"];
  const education = ["Attended high school (or equivalent)","High school graduate (or equivalent)","Attended college or university","College degree","University degree","Multiple degrees","PHD or higher"];

//   const persValues = ["Self-Direction", "Stimulation", "Hedonism", "Power", "Security", "Conformity", "Tradition", "Benevolence", "Universalism"];
//   const polSpectrum = ["Economic-Left", "Economic-Right", "Moderate"];
//   const hobbies = [
//   'Animal care',
//   'Arts and crafts',
//   'Barhopping',
//   'Board Games',
//   'Camping, biking, hiking',
//   'Collecting',
//   'Cooking',
//   'Dancing',
//   'Fashion',
//   'Formal Science',
//   'Indoor Sports',
//   'Natural Science',
//   'Outdoor Sports',
//   'Photography',
//   'Playing Musical Instruments',
//   'Reading',
//   'Roleplay',
//   'Social Science',
//   'Social activism',
//   'Software engineering and CS',
//   'Theatre',
//   'Travel',
//   'Video games',
//   'Watching TV',
//   'Writing',
// ];
//
//  const socialActivism = ["Veganism", "Animal rights", "LGBTQ", "Free software", "Anti-globalism", "Feminism", "BLM"]

 let history = useHistory();

 const gotoSearch = () => {
   history.push(`/admin/search-results/`)
   return false;
 }

    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">

              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Search for the candidates</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={gotoSearch}
                        size="sm"
                      >
                        3 Results
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                <Form>
                  <div className="pl-lg-4">

                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">Talents and skills</CardHeader>
                    <CardBody>

                      <Row>

                        <Col lg="6">
                          <label className="form-control-label" htmlFor="input-status">Talents:</label>
                          <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
                            {talents.map(talent => { return <option>{talent}</option> })}
                          </Input>
                        </Col>
                        <Col lg="6">

                          <label className="form-control-label" htmlFor="input-status">Skills:</label>
                          <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
                            {skills.map(talent => { return <option>{talent}</option> })}
                          </Input>

                        </Col>

                      </Row>

                    </CardBody>
                  </Card>

                  <br/>

                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">City and location</CardHeader>
                    <CardBody>

                      <Row>

                        <Col lg="6">
                          <label className="form-control-label" htmlFor="input-status">City:</label>
                          <Input type="text" name="status" className="form-control-alternative" />
                        </Col>
                        <Col lg="6">

                          <label className="form-control-label" htmlFor="input-status">Relocation preferences:</label><br/>
                          &nbsp;&nbsp; <Label check><Input type="checkbox" id="checkbox2" />{' '}Ready to relocate</Label>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>

                  <br/>

                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">Language and education</CardHeader>
                    <CardBody>

                      <Row>

                        <Col lg="6">
                          <label className="form-control-label" htmlFor="input-status">Language:</label>
                          <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
                            {langs.map(talent => { return <option>{talent}</option> })}
                          </Input>
                        </Col>
                        <Col lg="6">
                          <label className="form-control-label" htmlFor="input-status">Education:</label>
                          <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
                            {education.map(talent => { return <option>{talent}</option> })}
                          </Input>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>

                  <br/>

                  </div>
                </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
/*
<Card className="bg-secondary shadow">
  <CardHeader className="bg-white border-0">Personality traits</CardHeader>
  <CardBody>

    <Row>

      <Col lg="6">
        <label className="form-control-label" htmlFor="input-status">Core Values:</label>
        <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
          {persValues.map(talent => { return <option>{talent}</option> })}
        </Input>
      </Col>
      <Col lg="6">
        <label className="form-control-label" htmlFor="input-status">Hobbies:</label>
        <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
          {hobbies.map(talent => { return <option>{talent}</option> })}
        </Input>
      </Col>

      </Row>
      <Row>

    </Row>

  </CardBody>
</Card>

<br/>

<Card className="bg-secondary shadow">
  <CardHeader className="bg-white border-0">Policical and Social Values</CardHeader>
  <CardBody>

    <Row>

      <Col lg="6">
        <label className="form-control-label" htmlFor="input-status">Political Spectrum:</label>
        <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
          {polSpectrum.map(talent => { return <option>{talent}</option> })}
        </Input>
      </Col>

      <Col lg="6">
        <label className="form-control-label" htmlFor="input-status">Social Activism:</label>
        <Input type="select" name="status" className="form-control-alternative" multiple style={{height:'115px'}}>
          {socialActivism.map(talent => { return <option>{talent}</option> })}
        </Input>
      </Col>

    </Row>

  </CardBody>
</Card>
*/

export default ManagerProfileNew;
