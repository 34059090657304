/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import { useLocation } from "react-router-dom";

import data from "../data.js";

const Managers = () => {

  let managersData = data.users.sort((u1, u2) => { return (u1.lastName > u2.lastName)*2 - 1 });

  const managerStyle = {
    display: 'inline',
    paddingLeft: '12px'
  }

  let location = useLocation();
  const patharray = location.pathname.split('/');
  const mode = patharray[patharray.length-1];

  let header = '';

  if (mode === 'hm') {
    managersData = managersData.filter(man => { return man.type === 'hm' })
    header = 'Team leads'
  } else {
    header = 'Users';
  }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">{header}</h3>
                  <br/>
                  <Button
                    color="primary"
                    href="/admin/hm-profile-new/"
                    size="sm"
                  >
                    Add new
                  </Button>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Role</th>
                      <th scope="col">Survey Completion</th>
                      <th scope="col">Ego.jobs Profile</th>
                    </tr>
                  </thead>
                  <tbody>
                    {managersData.map(manager => {
                      const path = `/admin/hm-profile/${manager.uid}/`;

                      const statusColor = { 'Active': 'success', 'Inactive': 'light' };
                      const surveyColor = num => {
                        if (num <= 10) return 'danger'
                        if (num <= 50) return 'warning'
                        return 'success';
                      }

                      return (
                        <tr>
                          <td>
                            <Media className="align-items-center">

                            <a
                              className="avatar avatar-lg"
                              href="#pablo"
                              id="tooltip742438047"
                              onClick={e => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="rounded-circle"
                                src={require(`assets/img/theme/${manager.uid}.jpg`)}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip742438047"
                            >
                             {manager.firstName} {manager.lastName} Profile
                            </UncontrolledTooltip>

                              <Media>
                                <span className="mb-0 text-sm">
                                <a href={path} style={managerStyle} data-id="hm1">{manager.firstName} {manager.lastName}</a>
                                </span>
                              </Media>
                            </Media>

                          </td>
                          <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className={`bg-${statusColor[manager.status]}`} />
                            {manager.status}
                          </Badge>
                          </td>
                          <td>
                          {manager.role}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="mr-2">{manager.surveyCompletion}%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value={manager.surveyCompletion}
                                  barClassName={`bg-${surveyColor(manager.surveyCompletion)}`}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href={`https://ego.jobs/en/h/${manager.egoJobsUsername}`}>See Ego.jobs Profile</a>
                          </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
}

export default Managers;
