/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import { useLocation } from "react-router-dom";

import data from "../data.js";

const Compatibility = () => {

  let location = useLocation();
  const patharray = location.pathname.split('/');
  const ruid = patharray[patharray.length-2];

  let usersData = data.users;

  const mainUser = usersData.find(({uid}) => uid === ruid );
  usersData = usersData.filter(({uid}) => uid !== ruid).sort((a,b)=>{ return (a.lastName > b.lastName)*2-1 });

  const managerStyle = {
    display: 'inline',
    paddingLeft: '12px'
  }

  const avatar = mainUser? require(`assets/img/theme/${mainUser.uid}.jpg`): '';
  let header = mainUser? <><img alt="..." width="90" className="rounded-circle" src={avatar}/> &nbsp; {mainUser.firstName} {mainUser.lastName} compatibility</>: 'Please select user';

  const colors = num => {
    if (num <= 10) return 'danger'
    if (num <= 50) return 'warning'
    return 'success';
  }

  const rand = () => {
    return parseInt(Math.random()*100)+1;
  }

  const scoreComp = (score) => {
    return (
          <>
          {score}%
          <div>
          <Progress
            max="100"
            value={score}
            barClassName={`bg-${colors(score)}`}
          />
        </div>
        </>
    )
  }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">{header}</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Overall Score (?)</th>
                      <th scope="col">Personality (?)</th>
                      <th scope="col">Core Values (?)</th>
                      <th scope="col">Common Compatibility (?)</th>
                      <th scope="col">Social Compatibility (?)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.map(manager => {
                      const path = `/admin/compatibility/${manager.uid}/`;

                      var scores = [];
                      for (var i=0;i<5;i++) {
                        scores.push(rand());
                      }

                      var total_score = parseInt((scores.reduce((sum, x) => sum + x, 0)) / 4);

                      return (
                        <tr>
                          <td>
                            <Media className="align-items-center">

                            <a
                              className="avatar avatar-lg"
                              href="#pablo"
                              id="tooltip742438047"
                              onClick={e => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="rounded-circle"
                                src={require(`assets/img/theme/${manager.uid}.jpg`)}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip742438047"
                            >
                             {manager.firstName} {manager.lastName} Profile
                            </UncontrolledTooltip>

                              <Media>
                                <span className="mb-0 text-sm">
                                <a href={path} style={managerStyle} data-id="hm1">{manager.firstName} {manager.lastName}</a>
                                </span>
                              </Media>
                            </Media>

                          </td>
                          <td>
                          {mainUser? scoreComp(total_score): ''}
                          </td>
                          <td>
                          {mainUser? scoreComp(scores[0]): ''}
                          </td>
                          <td>
                          {mainUser? scoreComp(scores[1]): ''}
                          </td>
                          <td>
                          {mainUser? scoreComp(scores[2]): ''}
                          </td>
                          <td>
                          {mainUser? scoreComp(scores[3]): ''}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
}

export default Compatibility;
