/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeaderStripped.js";
import { useLocation } from 'react-router-dom';

import data from "../data.js";

const ManagerProfile = () => {

  let location = useLocation();
  const patharray = location.pathname.split('/');
  const uid = patharray[patharray.length-2];

  const ManagerData = data.users.find(manager => { return manager.uid === uid });

  const [statusModal, setStatusModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);

  const statusModalToggle = () => setStatusModal(!statusModal);
  const saveDataToggle = () => setSaveModal(!saveModal);

  const onStatusChange = () => {
    statusModalToggle();
  }

  const onSaveData = () => {
    saveDataToggle();
  }

  const options = ['Active', 'Inactive'].map(status => {
    const isSelected = (status === ManagerData.status)? 'selected': '';
    return (<option selected={isSelected}>{status}</option>)
  })

    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require(`assets/img/theme/${ManagerData.uid}.jpg`)}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {ManagerData.firstName} {ManagerData.lastName}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {ManagerData.location}
                    </div>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      <a href="mailto:">{ManagerData.email}</a>
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {ManagerData.role}
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Survey Completion: {ManagerData.surveyCompletion}%
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">User Profile</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={onSaveData}
                        size="sm"
                      >
                        Save Data
                      </Button>

                      <Modal isOpen={saveModal} toggle={saveDataToggle} >
                        <ModalHeader toggle={saveDataToggle}>Save Data</ModalHeader>
                        <ModalBody>
                        Your changes has been saved
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={saveDataToggle}>OK</Button>{' '}
                        </ModalFooter>
                      </Modal>

                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={ManagerData.firstName}
                            id="input-first-name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={ManagerData.lastName}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-status"
                          >
                            Status
                          </label>

                          <Modal isOpen={statusModal} toggle={statusModalToggle} >
                            <ModalHeader toggle={statusModalToggle}>Change Status</ModalHeader>
                            <ModalBody>
                            Are you sure you want to change user status?
                            </ModalBody>
                            <ModalFooter>
                              <Button color="primary" onClick={statusModalToggle}>Yes</Button>{' '}
                              <Button color="secondary" onClick={statusModalToggle}>Cancel</Button>
                            </ModalFooter>
                          </Modal>

                          <Input type="select" name="status" onChange={onStatusChange} id="exampleSelect" className="form-control-alternative">
                            <option>Choose</option>
                            {options}
                          </Input>

                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="email"
                            value={ManagerData.email}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ego.jobs Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={ManagerData.egoJobsUsername}
                              id="input-username"
                              placeholder="Username"
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Location
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="text"
                              value={ManagerData.location}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Role
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={ManagerData.role}
                              id="input-first-name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Survey Completion
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={ManagerData.surveyCompletion}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

export default ManagerProfile;
