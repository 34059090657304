/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeaderStripped.js";
import { useLocation } from 'react-router-dom';

import data from "../data.js";

const JobPost = () => {

  let location = useLocation();
  const patharray = location.pathname.split('/');
  const uid = patharray[patharray.length-2];
  const JobData = data.requisitions.find(job => { return job.uid === uid });

  const [modal, setModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);

  const toggle = () => setModal(!modal);

  const saveDataToggle = () => setSaveModal(!saveModal);

  const onSaveData = () => {
    saveDataToggle();
  }

  const onStatusChange = () => {
    toggle();
  }

    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card>
                <CardBody>
                  <div className="text-center">
                    <h3>
                      {JobData.title}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Job post preview
                    </div>
                    <div><img src={require("assets/img/job-post-ego.png")} alt="Preview" /></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Requisition Details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={onSaveData}
                        size="sm"
                      >
                        Save
                      </Button>

                      <Modal isOpen={saveModal} toggle={saveDataToggle} >
                        <ModalHeader toggle={saveDataToggle}>Change Status</ModalHeader>
                        <ModalBody>
                        Are you sure you want to change requisition? This change will affect all the job posts.
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={saveDataToggle}>Yes</Button>{' '}
                          <Button color="secondary" onClick={saveDataToggle}>Cancel</Button>
                        </ModalFooter>
                      </Modal>

                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">

                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-status"
                          >
                            Status
                          </label>

                          <Modal isOpen={modal} toggle={toggle} >
                            <ModalHeader toggle={toggle}>Change Status</ModalHeader>
                            <ModalBody>
                            Are you sure you want to change job requisition status? This will affect all your job posts.
                            </ModalBody>
                            <ModalFooter>
                              <Button color="primary" onClick={toggle}>Yes</Button>{' '}
                              <Button color="secondary" onClick={toggle}>Cancel</Button>
                            </ModalFooter>
                          </Modal>

                          <Input type="select" name="status" onChange={onStatusChange} id="exampleSelect" className="form-control-alternative">
                            <option>Choose</option>
                            <option>Inactive</option>
                            <option selected>Active</option>
                          </Input>

                        </FormGroup>

                      </Col>
                      <Col lg="6">

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Title
                        </label>

                        <Input
                          className="form-control-alternative"
                          defaultValue={JobData.title}
                          id="input-username"
                          placeholder="Title"
                          type="text"
                        />

                      </FormGroup>

                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          Talents
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          placeholder="Talents"
                          type="textarea"
                          rows="4"
                          value={JobData.talents}
                        />
                      </FormGroup>

                      </Col>
                      <Col lg="6">

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Skills
                        </label>

                        <Input
                          className="form-control-alternative"
                          defaultValue={JobData.skills}
                          id="input-username"
                          placeholder="Skills"
                          type="text"
                        />

                      </FormGroup>

                      </Col>
                    </Row>

                      <Row>
                        <Col lg="6">

                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            URL
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Talents"
                            type="text"
                            value={JobData.url}
                          />
                        </FormGroup>

                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Description
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="jesse@example.com"
                              type="textarea"
                              value={JobData.description}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

export default JobPost;
