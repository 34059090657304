const data = {
  users: [{
      uid: 'hm1',
      type: 'hm',
      firstName: 'Alice',
      lastName: 'Chen',
      role: 'CTO',
      status: 'Active',
      location: 'Berlin',
      surveyCompletion: 60,
      email: "alice@acme-company.com",
      egoJobsUsername: "SuccessfulFuchsiaCoral"
    },
    {
      uid: 'hm2',
      type: 'hm',
      firstName: 'Tomash',
      lastName: 'Levitsky',
      role: 'Head of Devops ',
      status: 'Active',
      location: 'Poznan',
      surveyCompletion: 90,
      requisitions: []
    },
    {
      uid: 'hm3',
      type: 'hm',
      firstName: 'Frauke',
      lastName: 'Staldeker',
      role: 'VP of Sales ',
      status: 'Active',
      location: 'Berlin',
      surveyCompletion: 90,
      requisitions: []
    },
    {
      uid: 'hm4',
      type: 'hm',
      firstName: 'Roberto',
      lastName: 'Covarrubias',
      role: 'COO ',
      status: 'Inactive',
      location: 'London',
      surveyCompletion: 10,
      requisitions: []
    },
    {
      uid: 'u1',
      firstName: 'Andrés Santana',
      lastName: 'Soto',
      role: 'QA Engineer',
      status: 'Active',
      location: 'London',
      surveyCompletion: 60,
      email: "andres@acme-company.com",
      egoJobsUsername: "CheerfulBlueCrane"
    },
    {
      uid: 'u2',
      firstName: 'Ryouko',
      lastName: 'Ochiai',
      role: 'Hardware Engineer',
      status: 'Inactive',
      location: 'London',
      surveyCompletion: 30,
      email: "ryouko@acme-company.com",
      egoJobsUsername: "CapableOlivePiranha"
    },
    {
      uid: 'u3',
      firstName: 'Aurora',
      lastName: 'DeRose',
      role: 'SMM Manager',
      status: 'Active',
      location: 'Berlin',
      surveyCompletion: 60,
      email: "derose@acme-company.com",
      egoJobsUsername: "DyingAmethystCanidae"
    },
    {
      uid: 'u4',
      firstName: 'Eden',
      lastName: 'Omar',
      role: 'Graphic Designer',
      status: 'Active',
      location: 'Berlin',
      surveyCompletion: 90,
      email: "eden@acme-company.com",
      egoJobsUsername: "WelshIndigoWren"
    }
  ],
  compatibility: {
    "u1": {
      "hm1": {
        coreValues: 30,
        personality: 80,
        lifeStyle: 10,
        background: 50
      },
      "hm2": {
        coreValues: 10,
        personality: 30,
        lifeStyle: 50,
        background: 90
      },
    }
  },
  requisitions: [{
      uid: "backend1",
      hm: ["hm1", "hm2"],
      title: "Backend Engineer (w/m/d)",
      talents: "Web Backend Developer",
      skills: "Python, Django, REST API design, AWS, Terraform, Android background services",
      url: "backend1",
      description: "",
      stats: {
        views: 12,
        engagements: 3,
        interviews: 0,
        rejects: 3
      },
      publishDate: "1 Jan 2021",
      engagements: [{
          hm: "hm2",
          score: 40,
          username: "CapableOlivePiranha",
          city: "Berlin",
          lastUpdate: "12 Jan 2021",
          status: "Connected"
        },
        {
          hm: "hm1",
          score: 60,
          username: "LiquidTealEagle",
          city: "N/A",
          lastUpdate: "12 Feb 2021",
          status: "Waiting for reply"
        },
        {
          hm: "hm1",
          score: 90,
          username: "MammothScarletToad",
          city: "Amsterdam",
          lastUpdate: "3 March 2021",
          status: "Waiting for approve"
        },
      ]
    },
    {
      uid: "qa-sen1",
      hm: ["hm1"],
      title: "Senior automation specialist (M/F/D)",
      skills: "",
      description: "",
      stats: {
        views: 33,
        engagements: 12,
        interviews: 1,
        rejects: 7
      }
    },
    {
      uid: "jun-sre1",
      hm: ["hm2"],
      title: "Junior SRE",
      skills: "",
      description: "",
      stats: {
        views: 33,
        engagements: 12,
        interviews: 1,
        rejects: 7
      }
    },
    {
      uid: "sen-devop-tl2",
      hm: ["hm2"],
      title: "SRE Teamlead (M/F/D)",
      skills: "",
      description: "",
      stats: {
        views: 33,
        engagements: 12,
        interviews: 1,
        rejects: 7
      }
    },

  ],
  leads: [{
      hm: "hm1",
      score: 40,
      username: "CapableOlivePiranha",
      city: "Berlin",
      lastUpdate: "12 Jan 2021",
      status: "Connected"
    },
    {
      hm: "hm1",
      score: 60,
      username: "LiquidTealEagle",
      city: "N/A",
      lastUpdate: "12 Feb 2021",
      status: "Waiting for reply"
    },
    {
      hm: "hm1",
      score: 90,
      username: "MammothScarletToad",
      city: "Amsterdam",
      lastUpdate: "3 March 2021",
      status: "Waiting for approve"
    }
  ]

}

export default data;
