/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import { useLocation } from 'react-router-dom';

import data from "../data.js";

const Engagements = () => {

  let location = useLocation();
  const patharray = location.pathname.split('/');
  const uid = patharray[patharray.length-2];

  const requisition = data.requisitions.find(req => { return req.uid === uid });
  const engagementsData = requisition.engagements || [];

  const colors = num => {
    if (num <= 10) return 'danger'
    if (num <= 50) return 'warning'
    return 'success';
  }

  const scoreComp = (score) => {
   return (
         <>
         {score}%
         <div>
         <Progress
           max="100"
           value={score}
           barClassName={`bg-${colors(score)}`}
         />
       </div>
       </>
   )
 }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Engagements</h3>
                  <div>
                    <b>Requistision:</b> {requisition.title}
                  </div>
                  <div>
                    <b>Online since:</b> {requisition.publishDate}
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Username</th>
                      <th scope="col">Status</th>
                      <th scope="col">Compatibility Score</th>
                      <th scope="col">City</th>
                      <th scope="col">Last Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {engagementsData.map(engagement => {
                      // const engagementsPath = `/admin/engagements/${job.uid}/`;

                      // const hms = job.hm.map(hm_uid => {
                      //   return data.managers.find(manager => { return manager.uid === hm_uid })
                      // });

                      const hmData = data.users.find(user => { return user.uid === engagement.hm });

                      return (
                        <tr>
                          <td>
                          <span className="mb-0 text-sm">
                            <a href={`https://ego.jobs/en/u/${engagement.username}`} data-id="hm1">{engagement.username}</a>
                          </span>
                          </td>
                          <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            Active
                          </Badge>
                          </td>
                          <td>
                          {hmData.firstName} {hmData.lastName}<br/>
                          {scoreComp(engagement.score)}
                          </td>
                          <td>{engagement.city}</td>
                          <td>{engagement.lastUpdate}</td>
                          </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
}

export default Engagements;
