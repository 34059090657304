/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import data from "../data.js";

const SearchResults = () => {

  const leads = data.leads;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Search results</h3>

                  <br/>
                  <Badge color="primary" pill>Scrum Master, Agile Coach</Badge> &nbsp;
                  <Badge color="primary" pill>London</Badge> &nbsp;
                  <Badge color="primary" pill>Berlin</Badge> &nbsp;
                  <Badge color="primary" pill>English</Badge> &nbsp;
                  <Badge color="primary" pill>German</Badge> &nbsp;
                  <Badge color="primary" pill>College Degree or Higher</Badge> &nbsp;

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Username</th>
                      <th scope="col">Status</th>
                      <th scope="col">City</th>
                      <th scope="col">Last Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leads.map(engagement => {
                      // const engagementsPath = `/admin/engagements/${job.uid}/`;

                      // const hms = job.hm.map(hm_uid => {
                      //   return data.managers.find(manager => { return manager.uid === hm_uid })
                      // });

                      return (
                        <tr>
                          <td>
                          <span className="mb-0 text-sm">
                            <a href={`https://ego.jobs/en/u/${engagement.username}`} data-id="hm1">{engagement.username}</a>
                          </span>
                          </td>
                          <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            Active
                          </Badge>
                          </td>
                          <td>{engagement.city}</td>
                          <td>{engagement.lastUpdate}</td>
                          </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
}

/*
<Badge color="primary" pill>Outdoor Sports</Badge> &nbsp;
<Badge color="primary" pill>Vegan</Badge> &nbsp;
<Badge color="primary" pill>LGBTQ</Badge>
*/

export default SearchResults;
